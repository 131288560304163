<template>
  <div id="recruit">
    <div class="pc">
      <img src="../../assets/recruit/banner.png" alt="" class="banner" />
      <div class="container">
        <h1>招纳贤士</h1>
        <div><img src="../../assets/home/line.png" alt="" class="" /></div>
        <p>找专业的人才，做专业事</p>
        <div class="table" v-if="state">
          <el-table
            :data="list"
            stripe
            style="width: 100%"
            :header-cell-style="{
              background: 'rgba(57, 139, 251, 1)',
              color: '#fff',
              height: '6.875rem',
            }"
            :row-style="{ height: '6.875rem' }"
          >
            <el-table-column prop="title" label="招聘岗位"> </el-table-column>
            <el-table-column prop="number" label="招聘人数"> </el-table-column>
            <el-table-column prop="address" label="工作地点"> </el-table-column>
            <el-table-column prop="updateTime" label="发布时间">
            </el-table-column>
            <el-table-column label="" align="center" width="80px">
              <template slot-scope="scope">
                <i class="el-icon-d-arrow-right" @click="detail(scope)"></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="detail" v-else>
          <Details :info="info" @func="getMsgFormSon"></Details>
        </div>
        <div class="pagination" v-show="state">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            :current-page="pageNum"
            layout="prev, pager, next, jumper"
            :page-count="Number(pages)"
          >
          </el-pagination>
        </div>
      </div>
      <img src="../../assets/recruit/bgc1.png" alt="" class="bgc1" />
    </div>
    <div class="mobile">
      <img src="../../assets/recruit/banner.png" alt="" class="banner" />
      <div class="table">
        <div class="table" v-if="state">
          <el-table
            :data="list"
            stripe
            style="width: 100%"
            :header-cell-style="{
              background: 'rgba(57, 139, 251, 1)',
              color: '#fff',
            }"
          >
            <el-table-column prop="title" label="招聘岗位"> </el-table-column>
            <el-table-column prop="number" label="招聘人数"> </el-table-column>
            <el-table-column prop="address" label="工作地点"> </el-table-column>
            <el-table-column prop="updateTime" label="发布时间">
            </el-table-column>
            <el-table-column label="" width="40">
              <template slot-scope="scope">
                <i class="el-icon-d-arrow-right" @click="detail(scope)"></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="detail" v-else>
          <Details :info="info" @func="getMsgFormSon"></Details>
        </div>
      </div>
      <img src="../../assets/recruit/m_bgc1.png" alt="" class="banner" />
    </div>
  </div>
</template>

<script>
import Details from "./details";
import { get_position } from "@/api/all";

export default {
  components: { Details },
  data() {
    return {
      state: true,
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      list: [],
      pageNum: 1,
      pageSize: 7,
      pages:"",
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    getlist() {
      get_position({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      })
        .then((res) => {
          if (res.code == 0) {
            this.list = res.data.list;
            this.pages = res.data.pages;
            this.pageNum = res.data.pageNum;
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    detail(scope) {
      this.state = false;
      this.info = scope.row;
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getlist();
    },
    getMsgFormSon() {
      this.state = true;
      this.getlist();
    },
  },
};
</script>

<style lang="less" scoped>
#recruit {
  .pc {
    .banner {
      width: 100%;
    }
    .container {
      width: 100%;
      height: auto;
      // height: 81.5rem;
      background-image: url(../../assets/recruit/bgc2.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: -1;
      h1 {
        font-size: 30px;
        padding: 3.5rem 0 1rem 0;
        color: rgba(13, 13, 13, 1);
      }
      p {
        font-size: 14px;
        color: rgba(112, 112, 112, 1);
      }
      .table {
        width: 90%;
        //   height: 56.25rem;
        //   background-color: pink;
        margin: auto;
        margin-top: 90px;
      }
          .pagination {
        width: 80%;
        margin: auto;
        text-align: right;
        padding: 20px 0;
        .el-pagination {
          margin-right: 40px;
        }
      }
      .detail {
        width: 90%;
          // height: 56.25rem;
        //   background-color: pink;
        // height: 1400px;

        margin: 40px auto;
        background-color: #fff;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
      }
      .cell {
        font-size: 30px;
      }
    }
    .bgc1 {
      display: flex;
      width: 100%;
    }
  }
  .mobile {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  #recruit {
    .pc {
      display: none;
    }
    .mobile {
      display: block;
      .banner {
        width: 100%;
        display: flex;
      }
      .table {
        width: 98%;
        // margin: 0 5px;
        margin: 15px auto;
      }
    }
  }
}
</style>
