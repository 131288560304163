var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"recruit"}},[_c('div',{staticClass:"pc"},[_c('img',{staticClass:"banner",attrs:{"src":require("../../assets/recruit/banner.png"),"alt":""}}),_c('div',{staticClass:"container"},[_c('h1',[_vm._v("招纳贤士")]),_vm._m(0),_c('p',[_vm._v("找专业的人才，做专业事")]),(_vm.state)?_c('div',{staticClass:"table"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"stripe":"","header-cell-style":{
            background: 'rgba(57, 139, 251, 1)',
            color: '#fff',
            height: '6.875rem',
          },"row-style":{ height: '6.875rem' }}},[_c('el-table-column',{attrs:{"prop":"title","label":"招聘岗位"}}),_c('el-table-column',{attrs:{"prop":"number","label":"招聘人数"}}),_c('el-table-column',{attrs:{"prop":"address","label":"工作地点"}}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"发布时间"}}),_c('el-table-column',{attrs:{"label":"","align":"center","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('i',{staticClass:"el-icon-d-arrow-right",on:{"click":function($event){return _vm.detail(scope)}}})]}}],null,false,2246729133)})],1)],1):_c('div',{staticClass:"detail"},[_c('Details',{attrs:{"info":_vm.info},on:{"func":_vm.getMsgFormSon}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.state),expression:"state"}],staticClass:"pagination"},[_c('el-pagination',{attrs:{"background":"","page-size":_vm.pageSize,"current-page":_vm.pageNum,"layout":"prev, pager, next, jumper","page-count":Number(_vm.pages)},on:{"current-change":_vm.handleCurrentChange}})],1)]),_c('img',{staticClass:"bgc1",attrs:{"src":require("../../assets/recruit/bgc1.png"),"alt":""}})]),_c('div',{staticClass:"mobile"},[_c('img',{staticClass:"banner",attrs:{"src":require("../../assets/recruit/banner.png"),"alt":""}}),_c('div',{staticClass:"table"},[(_vm.state)?_c('div',{staticClass:"table"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"stripe":"","header-cell-style":{
            background: 'rgba(57, 139, 251, 1)',
            color: '#fff',
          }}},[_c('el-table-column',{attrs:{"prop":"title","label":"招聘岗位"}}),_c('el-table-column',{attrs:{"prop":"number","label":"招聘人数"}}),_c('el-table-column',{attrs:{"prop":"address","label":"工作地点"}}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"发布时间"}}),_c('el-table-column',{attrs:{"label":"","width":"40"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('i',{staticClass:"el-icon-d-arrow-right",on:{"click":function($event){return _vm.detail(scope)}}})]}}],null,false,2246729133)})],1)],1):_c('div',{staticClass:"detail"},[_c('Details',{attrs:{"info":_vm.info},on:{"func":_vm.getMsgFormSon}})],1)]),_c('img',{staticClass:"banner",attrs:{"src":require("../../assets/recruit/m_bgc1.png"),"alt":""}})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{attrs:{"src":require("../../assets/home/line.png"),"alt":""}})])}]

export { render, staticRenderFns }