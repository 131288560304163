<template>
  <div id="detail">
    <h1><i class="el-icon-back" @click="back"></i> 招聘信息</h1>
    <div id="content" ref="message" v-html="info.content">
      <!-- {{info.content}} -->
    </div>
    <div class="button">
      <el-button
        type="primary"
        @click="centerDialogVisible = true"
        class="button_pc"
        >立即申请</el-button
      >
      <el-button
        type="primary"
        @click="centerDialogVisible1 = true"
        class="button_mobile"
        size="mini"
        >立即申请</el-button
      >
    </div>
    <div class="dialog_pc">
      <el-dialog
        title="提示"
        :visible.sync="centerDialogVisible"
        width="28rem"
        center
      >
        <div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="60px"
            class="demo-ruleForm"
          >
            <el-form-item label="姓名" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="电话" prop="phone" class="phone">
              <el-input v-model="ruleForm.phone"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >确认提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
    <div class="dialog_mobile">
      <el-dialog
        title="申请信息填写"
        :visible.sync="centerDialogVisible1"
        width="300px"
        center
      >
        <div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="55px"
            class="demo-ruleForm"
          >
            <el-form-item label="姓名" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="电话" prop="phone" class="phone">
              <el-input v-model="ruleForm.phone"></el-input>
            </el-form-item>
            <el-form-item prop="phone" class="buttons">
              <el-button type="primary" @click="submitForm('ruleForm')"
                >确认提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { get_apply } from "@/api/all";

export default {
  props: ["info"],
  data() {
    return {
      username: "",
      phone: "",
      centerDialogVisible: false,
      centerDialogVisible1: false,
      ruleForm: {
        name: "",
        phone: "",
        desc: "",
      },
      rules: {
        name: [{ required: true, message: "请输入您的名字", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入您的电话", trigger: "blur" },
          { min: 11, max: 11, message: "电话号码有误", trigger: "blur" },
        ],
        desc: [{ required: true, message: "留言不能为空", trigger: "blur" }],
      },
    };
  },
  mounted() {
    // console.log(this.$refs.message);
    let content = document.querySelector("#content");
    content.innerHTML = this.info.content;
    console.log( this.info,"sss",content);
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        //开启校验
        if (valid) {
          get_apply({
            id: this.info.id,
            name: this.ruleForm.name,
            phone: this.ruleForm.phone,
          })
            .then((res) => {
              if (res.code == 0) {
                this.centerDialogVisible = false;
                this.centerDialogVisible1 = false;
                this.$message.success("申请成功");
                this.$emit("func");
                this.ruleForm.name = "";
                this.ruleForm.phone = "";
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    back() {
      this.$emit("func");
    },
  },
};
</script>

<style lang="less" scoped>
#detail {
  // .dialog_mobile {
  //   display: none;
  // }
  margin: auto;
  .button_mobile {
    display: none;
  }
  h1 {
    display: flex;
    align-items: center;
    font-size: 30px;
    color: rgba(56, 56, 56, 1);
    text-align: left;
    margin-left: 50px;
    position: relative;
    i {
      font-size: 30px;
      position: absolute;
      left: -40px;
    }
  }
  #content {
    font-size: 16px;
    color: rgba(56, 56, 56, 1);
    text-align: left;
    padding-left: 50px;
    line-height: 35px;
  }
  //   #content{
  //   font-size: 12px;
  //   color: rgba(128, 128, 128, 1);
  //   padding: 0;
  //   margin-left: 20px;
  //   line-height: 35px;
  // }
  .button {
    padding: 20px 0;
    text-align: right;
    margin-right: 100px;
  }

  .el-form {
    .el-form-item__content {
      width: 80% !important;

      .el-input {
        width: 19rem;
      }
      .el-button {
        //   margin-left: 24px;
        width: 19rem;
        .el-form-item__error {
          display: none !important;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  #detail {
    width: 90%;
    padding: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    margin: auto;
    // .dialog_pc {
    //   display: none;
    // }
    // .dialog_mobile {
    //   display: block;
    // }
    .button_mobile {
      display: block;
    }
    h1 {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: rgba(56, 56, 56, 1);
      padding: 0;
      position: relative;
      margin-left: 20px;
      i {
        font-size: 12px;
        position: absolute;
        left: -20px;
      }
    }
    #content {
      font-size: 12px;
      color: rgba(128, 128, 128, 1);
      padding: 0;
      margin-left: 20px;
      line-height: 35px;
    }
    .button_pc {
      display: none;
    }
    .button {
      display: flex;
      // padding: 0;
      // margin: 0;
      .el-button {
        margin-left: 20px;
      }
    }
    .el-form {
      .el-form-item__content {
        width: 80% !important;
        .el-input {
          width: 180px;
        }
        .el-button {
          //   margin-left: 24px;
          width: 180px;
        }
      }
    }
  }
}
</style>
